import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'É hora de pensar além do convencional e alcançar novos horizontes.',
    paragraph: 'Nós transformamos suas ideias em realidade, utilizando a tecnologia como uma ferramenta de transformação. Nós criamos soluções inovadoras, personalizadas e exclusivas para o seu negócio, que o ajudarão a alcançar novos patamares de sucesso. Juntos, vamos desafiar o status quo e criar um futuro brilhante para a sua empresa.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01b.svg')}
                      alt="Cartão de visita digital."
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Cartão de Visita Digital
                    </h4>
                  <p className="m-0 text-sm">
                  O Cartão de visita digital oferece vantagens como: facilidade de compartilhamento, acessibilidade, economia de tempo e dinheiro,
                   possibilidade de atualização e personalização, além de possibilitar acesso a informações mais completas e atualizadas sobre o profissional ou a empresa.
                   Além das vantagens já mencionadas, você pode incluir um formulário para captura de leads, botão "vá" do Uber 
                   e muitas outras opções, permitindo ampliar sua base de clientes e melhorar a eficiência de sua estratégia de marketing.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Catálogo Digital
                    </h4>
                  <p className="m-0 text-sm">
                  O catálogo digital é uma ferramenta moderna e eficiente para apresentar seus produtos ou serviços. Ele é ecologicamente correto e oferece uma experiência de compra interativa, com carrinho de compras ativo, apresentação infinita de produtos, acessibilidade e mobilidade e maior poder de alcance. Além disso, ele inclui um sistema de agendamentos, permitindo marcar consultas ou reservas com facilidade. Ele também permite o controle das informações e reduz os custos.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03b.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Cardápio Digital
                    </h4>
                  <p className="m-0 text-sm">
                  A interação facilita o cliente ter uma experiência ampla, clara e objetiva em se comunicar com você e seus produtos. 
                  Além disso você pode inserir o carrinho de compras com botão ativo, onde através de poucos cliques, seus clientes
                  conseguem realizar pedidos com mais rapidez, praticidade e conforto. 
                  E agora com uma super novidade, o catálogo digital oferece também um sistema de comandas, 
                  permitindo aos clientes fazer pedidos e pagamentos de forma rápida e eficiente, melhorando ainda mais sua experiência de compra.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06b.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Cartão com Tecnologia de Aproximação (NFC)
                    </h4>
                  <p className="m-0 text-sm">
                  Incluir um cartão NFC no seu projeto é uma excelente maneira de amplificar a divulgação da sua marca e facilitar a 
                  interação com seus clientes. Com um cartão NFC, você pode compartilhar seu cartão de visitas digital, 
                  catálogo de produtos, cardápio, ou qualquer outro link que desejar de maneira simples e prática. 
                  Além disso, esses cartões são fáceis de usar, interativos, acessíveis, personalizáveis e econômicos, e são transportáveis, 
                  dispensando a necessidade de ter um estoque de cartões impressos.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05b.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Landing pages
                    </h4>
                  <p className="m-0 text-sm">
                  Com o nosso serviço de criação de landing pages, você pode alcançar seus objetivos de marketing de maneira eficiente e profissional. 
                  Nós criamos landing pages personalizadas e otimizadas para conversão, que ajudam a aumentar suas taxas de conversão e aumentar 
                  as chances de sucesso de suas campanhas de marketing. Além disso, nós utilizamos as últimas técnicas de SEO e design para garantir 
                  que sua landing page seja atraente e fácil de navegar, maximizando a experiência do usuário.
                  Gere mais leads, vendas ou qualquer outra ação desejada. Entre em contato conosco agora!
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06c.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} 
                      />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Sistemas Web de Gestão
                    </h4>
                  <p className="m-0 text-sm">
                  Nosso serviço de criação de sistemas web é a solução ideal para sua empresa, 
                  oferecemos sistemas personalizados e escaláveis, utilizando as últimas tecnologias e tendências 
                  para garantir sistemas modernos, seguros e fáceis de usar. Suporte contínuo e manutenção são oferecidos também. Entre em contato conosco agora!
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;