import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Saiba mais!',
    paragraph: 'Conheça um pouco mais os nossos serviços.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Seus principais contatos sempre a disposição
                  </div>
                <h3 className="mt-0 mb-12">
                  Cartão de Visitas Digital
                  </h3>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar domínio</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar hospedagem</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Cartão com Tecnologia NFC</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícone das Redes Sociais</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Logo e Foto</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Personalizado</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link de Presentes</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Animação nos Botões </p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícones nos Botões</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Vídeo do YouYube</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Google Maps</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link Va de Uber</p>
                <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Certificado se Segurança (SSL)</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Cartão de Visitas Digital"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Seus Produtos e serviços sempre atualizados
                  </div>
                  <h3 className="mt-0 mb-12">
                  Catálogo Digital
                    </h3>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar domínio</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar hospedagem</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link para compartilhamento</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícone das Redes Sociais</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Cadastro de produtos</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Sistema de Agendamentos</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Personalizado</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Formulário de Captura de Leads</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Animação nos Botões </p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícones nos Botões</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Vídeo do YouYube</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Google Maps</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link Vá de Uber</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Certificado se Segurança (SSL)</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Catálogo Digital"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Evite erros ao anotar pedidos e comandas
                  </div>
                <h3 className="mt-0 mb-12">
                  Cardápio Digital
                  </h3>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar domínio</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Não precisa pagar hospedagem</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link para compartilhamento</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícone das Redes Sociais</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Cadastro de produtos</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Logo e Foto</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Botão de compra ativo</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Carrinho de compra</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Calculo de frete automático </p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Personalizado</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Sistema de Comandas</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Animação nos Botões </p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Ícones nos Botões</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Vídeo do YouYube</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Google Maps</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Link Va de Uber</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Certificado se Segurança (SSL)</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Cardápio Digital"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Almente a conversão e retenção dos seus leads
                  </div>
                  <h3 className="mt-0 mb-12">
                  Landing pages
                    </h3>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Aumenta a taxa de conversão</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Melhora a experiência do usuário</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Foco na mensagem principal</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Facilidade para mensurar resultados</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Permite segmentação de público</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Otimização para mecanismos de busca</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Possibilidade de testes A/B</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Segmentação de público-alvo</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Personalização de mensagens</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Experiência simplificada para usuários</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Melhora na geração de leads</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Aumento da confiança e credibilidade</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Otimização para dispositivos móveis</p>
                  <p className="m-0"><span role="img" aria-label="checkmark">✔️</span> Redução de distrações e aumento da clareza.</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-04.png')}
                  alt="landing page"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;