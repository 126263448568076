import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Histórias de sucesso',
    paragraph: 'Nossa seção de depoimentos compartilha feedbacks positivos de nossos clientes e como nossos serviços os ajudaram a alcançar seus objetivos.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Trabalhar com a Tecmash foi incrível. Eles foram muito úteis e compreensivos em entender as necessidades do meu negócio e 
                    desenvolver um catálogo digital que atendeu perfeitamente aos meus objetivos. A transição para um catálogo digital foi incrivelmente fácil e 
                    eficiente, e temos visto um aumento significativo no tráfego e nas conversões desde então. Além disso, o catálogo é totalmente interativo e fácil 
                    de usar para nossos clientes, o que torna a experiência de compra mais agradável. Altamente recomendado!
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Juliana</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Catálogo Digital</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Adquirir um cartão de visitas digital com NFC foi uma excelente decisão. 
                    Minhas informações de contato foram compartilhadas facilmente e isso aumentou minhas oportunidades de 
                    negócios e fortaleceu minha marca. Recomendo fortemente o uso de um cartão de visitas digital com NFC para qualquer empreendedor.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Robson</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Cartão de Visitas Digital</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Ter um cardápio digital foi uma grande vantagem para o meu negócio. 
                    Não preciso mais imprimir cardápios, o que me economizou tempo e dinheiro. 
                    Além disso, pude incluir recursos interativos, como fotos dos pratos e opções de personalização, 
                    o que tornou a experiência do cliente ainda melhor. Os clientes também podem fazer pedidos diretamente pelo cardápio, 
                    o que tornou o processo muito mais eficiente.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Aline</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Cardápio Digital</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;